import React from "react"
import { containerCss, rippleCss } from "./index.styles"

const Loader = ({ fullScreen = true }) => {
  return (
    <div>
      <div className={containerCss({ fullScreen })}>
        <div className={rippleCss}>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default Loader
