import React from "react"
import { FormattedMessage } from "react-intl"
import mainCss from "./index.styles"

const SocketError = () => (
  <div className={mainCss}>
    <p className="has-text-weight-bold margin-bottom-8">
      <FormattedMessage id="Socket error - Title" />
    </p>

    <p>
      <FormattedMessage id="Socket error - Subtitle" />
    </p>
  </div>
)

export default SocketError
