import { cx, css, keyframes } from "emotion"

export const containerCss = ({ fullScreen }) =>
  cx(
    fullScreen
      ? css`
          height: 100vh;
        `
      : ""
  )

const ripple = keyframes`
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
`

export const rippleCss = cx(css`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -32px;
  margin-left: -32px;
  width: 64px;
  height: 64px;

  div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: ${ripple} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  div:nth-child(2) {
    animation-delay: -0.5s;
  }
`)
