export default (state, action) => {
  switch (action.type) {
    case "GET_STATE":
      return state
    case "LOADING_TOGGLE":
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: action.payload,
        },
      }
    case "LOADING_RESULTS_TOGGLE":
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingResults: action.payload,
        },
      }
    case "SOCKET_ERROR_TOGGLE":
      return {
        ...state,
        ui: { ...state.ui, socketError: action.payload },
      }
    case "LIVES_MODAL_TOGGLE":
      return {
        ...state,
        ui: {
          ...state.ui,
          isLivesModalOpen: action.payload,
        },
      }
    case "ANSWERED_TOGGLE":
      return {
        ...state,
        ui: {
          ...state.ui,
          answered: action.payload,
        },
      }
    case "ONLINE_USERS_UPDATE":
      return {
        ...state,
        onlineUsers: action.payload,
      }
    case "ENTRY_UPDATE":
      return {
        ...state,
        entry: {
          ...state.entry,
          ...action.payload,
        },
      }
    case "GAME_STATUS_UPDATE":
      return {
        ...state,
        game: {
          ...state.game,
          status: action.payload,
        },
      }
    case "GAME_DATA_UPDATE":
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    case "TIME_LEFT_TO_START_UPDATE":
      return {
        ...state,
        game: {
          ...state.game,
          timeLeftToStart: action.payload,
        },
      }
    case "TIME_LEFT_TO_NEXT_QUESTION_UPDATE":
      return {
        ...state,
        game: {
          ...state.game,
          timeLeftToNextQuestion: action.payload,
        },
      }
    case "PRIZE_PER_WINNER_SET":
      return {
        ...state,
        game: {
          ...state.game,
          prizePerWinner: action.payload,
        },
      }
    case "LIVES_COUNT_UPDATE":
      return {
        ...state,
        game: {
          ...state.game,
          livesCount: action.payload,
        },
      }
    case "EARNED_POINTS_UPDATE":
      return {
        ...state,
        game: {
          ...state.game,
          userEarnedPoints: action.payload,
        },
      }
    case "QUESTION_UPDATE": {
      return {
        ...state,
        question: {
          ...state.question,
          ...action.payload,
        },
      }
    }
    case "QUESTION_CLASSES_UPDATE":
      return {
        ...state,
        questionClasses: action.payload,
      }
    case "SECONDS_LEFT_TO_ANSWER_UPDATE":
      return {
        ...state,
        game: {
          ...state.game,
          timeLeftToAnswer: action.payload,
        },
      }
    case "QUESTION_RESULT_SHOW":
      return {
        ...state,
        question: {
          ...state.question,
          ...action.payload,
        },
      }
    case "QUESTION_MARK_AS_ANSWERED":
      return {
        ...state,
        question: {
          ...state.question,
          answerId: action.payload,
        },
      }
    case "WINNERS_SET":
      return {
        ...state,
        game: {
          ...state.game,
          ...action.payload,
        },
      }
    case "SPECTATOR_SET":
      return {
        ...state,
        game: {
          ...state.game,
          spectatorMode: action.payload,
          spectatorQuestion: action.payload ? state.question.id : null,
        },
      }
    case "LIFE_USE":
      return {
        ...state,
        game: {
          ...state.game,
          livesCount: state.game.livesCount - 1,
        },
      }
    case "RANKINGS_UPDATE":
      return {
        ...state,
        ui: {
          ...state.ui,
          rankingsLimit: state.ui.rankingsLimit + 5,
        },
        rankings: action.payload,
      }
    default:
      return state
  }
}
