import { css, cx } from "emotion"
import vars from "~utils/variables"

export const backgroundCss = ({ backgroundImage }) =>
  cx(
    css`
      background-color: ${vars.lifeRed};
      ${backgroundImage
        ? `background-image: url(${backgroundImage.file.url});`
        : ""}
    `,
    "modal-background"
  )

export const modalContentCss = cx(
  css`
    overflow: visible;
  `,
  "modal-content"
)

export const imageCss = cx(
  css`
    margin-top: -110px;
    max-width: 180px;
  `,
  "margin-bottom-24"
)

export const lifeHeartCss = ({ available }) =>
  cx(
    css`
      color: ${vars.lifeRed};
      font-size: ${vars.size1};
      opacity: ${available ? 1 : 0.5};
      margin: 0 10px;
    `,
    "fa",
    "fa-heart"
  )
