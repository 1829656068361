import React from "react"
import { FormattedMessage } from "react-intl"

const Modal = ({
  layout = "card",
  children,
  title,
  active,
  toggle,
  hasTopRightCloseButton,
}) => {
  return (
    <div className={`modal ${active ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      {layout === "card" ? (
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              <FormattedMessage id={title} />
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => toggle(false)}
            ></button>
          </header>
          <section className="modal-card-body">{children}</section>
          <footer className="modal-card-foot">
            <button className="button" onClick={() => toggle(false)}>
              <FormattedMessage id="modal.close" />
            </button>
          </footer>
        </div>
      ) : (
        <>
          <div className="modal-content">{children}</div>
          {hasTopRightCloseButton && (
            <button
              className="modal-close is-large"
              aria-label="close"
              onClick={() => toggle(false)}
            ></button>
          )}
        </>
      )}
    </div>
  )
}

export default Modal
