import React from "react"
import _ from "lodash"
import { injectIntl } from "react-intl"
import { cx, css } from "emotion"
import config from "~src/config"
import utils from "~utils"
import Button from "~components/_layout/button"
import Tag from "~components/_layout/tag"
import PointsBadge from "../points_badge"
import mainCss, {
  questionStatusCss,
  questionTextCss,
  answerButtonCss,
  pointsBadgeWrapperCss,
} from "./index.styles"

const jwt = utils._localStorage.get("jwt")

const Question = ({ i, state, status, socket, dispatch, userInfo, intl }) => {
  const questionIndex = i

  const questionColor = {
    STANDARD: "is-light-blue",
    GOLD: "is-gold",
  }

  const answerQuestion = id => {
    if (state.game.timeLeftToAnswer <= 0 || state.question.answerId) {
      return
    }

    const answerObject = {
      userId: userInfo.id,
      quizId: state.entry.id,
      questionId: state.question.id,
      answerId: id,
      accessToken: jwt,
    }

    socket.emit("answer_quiz_question", answerObject)

    dispatch({
      type: "ANSWERED_TOGGLE",
      payload: true,
    })
  }

  const isAnsweringDisabled = () => {
    return (
      state.ui.answered ||
      state.question.answerId ||
      state.game.timeLeftToAnswer <= 0
    )
  }

  const isAnswerPending = id => {
    return (
      state.question.answerId &&
      !state.question.correctAnswerId &&
      state.question.answerId === id
    )
  }

  const isAnswerCorrect = id => {
    return (
      state.question.correctAnswerId && state.question.correctAnswerId === id
    )
  }

  const isAnswerWrong = id => {
    return (
      state.question.answerId &&
      state.question.correctAnswerId &&
      state.question.answerId === id &&
      state.question.correctAnswerId !== id
    )
  }

  const getAnswerButtonClass = id => {
    if (isAnswerPending(id)) {
      return "is-pending has-text-weight-bold opacity-1"
    }

    if (isAnswerCorrect(id)) {
      return "is-success has-text-weight-bold opacity-1"
    }

    if (isAnswerWrong(id)) {
      return "is-danger has-text-weight-bold opacity-1"
    }

    return "is-outlined"
  }

  const getAnswerPercent = id => {
    if (
      !state.question.correctAnswerId ||
      !_.find(state.question.options, option => option.id === id).count
    ) {
      return 0
    }

    let totalAnswers = _.map(state.question.options, option => option.count)

    totalAnswers = totalAnswers.reduce((sum, n) => sum + n)

    const percent =
      (_.find(state.question.options, option => option.id === id).count * 100) /
      totalAnswers

    return percent || 2
  }

  return (
    <div className={cx(mainCss, questionStatusCss[status])}>
      {isAnswerCorrect(state.question.answerId) ? (
        <Tag className="is-success has-text-weight-bold">
          {intl.formatMessage({ id: "question.correct_answer" })}{" "}
          <i className="fa fa-check margin-left-8"></i>
        </Tag>
      ) : isAnswerWrong(state.question.answerId) ? (
        <Tag className="is-danger has-text-weight-bold">
          {intl.formatMessage({ id: "question.wrong_answer" })}{" "}
          <i className="fa fa-times margin-left-8"></i>
        </Tag>
      ) : (
        <Tag
          className={
            utils.getGameStatus(state) === "user-spectator"
              ? "is-spectator"
              : questionColor[state.question.type]
          }
        >
          {intl.formatMessage({ id: "question.question" })}{" "}
          {state.question.order}/{state.data.numberOfQuestions}
        </Tag>
      )}

      {state.question.type === "GOLD" && (
        <p className={cx("is-size-6", "has-text-grey", "margin-top-8")}>
          DOMANDA D'ORO
        </p>
      )}

      <h4 className={questionTextCss(utils.getGameStatus(state))}>
        {state.question.text}
      </h4>

      {state.question.options.map((option, i) => (
        <Button
          key={i}
          className={cx(answerButtonCss, getAnswerButtonClass(option.id))}
          onClick={() => answerQuestion(option.id)}
          disabled={isAnsweringDisabled()}
          testId={
            status === "active" ? `option-button-${questionIndex}-${i}` : "no"
          }
          localization={false}
        >
          <div className="options-percent">
            <div
              style={{
                width: `${getAnswerPercent(option.id)}%`,
                borderTopRightRadius:
                  getAnswerPercent(option.id) < 90 ? 5 : undefined,
                borderBottomRightRadius:
                  getAnswerPercent(option.id) < 90 ? 5 : undefined,
              }}
            />
          </div>
          <span>{option.text}</span>
          {option.count && 1 === 2 ? (
            <span className="option-count">{option.count}</span>
          ) : null}
          {isAnswerCorrect(option.id) && state.question.answerId === option.id && (
            <span className={pointsBadgeWrapperCss}>
              <PointsBadge
                points={
                  config.quizRules.points(
                    utils.getGameStatus(state) === "user-spectator"
                  )[state.question.type.toLowerCase()]
                }
              />
            </span>
          )}
        </Button>
      ))}
    </div>
  )
}

export default injectIntl(Question)
