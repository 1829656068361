import React from "react"
import _ from "lodash"
import { FormattedMessage } from "react-intl"
import Tag from "~components/_layout/tag"
import Button from "~components/_layout/button"
import LifeModalImg from "~images/lifemodal.png"
import {
  backgroundCss,
  imageCss,
  modalContentCss,
  lifeHeartCss,
} from "./index.styles"
import { cx, css } from "emotion"

const LifeModal = ({
  active,
  state,
  dispatch,
  userInfo,
  customerData,
  socket,
}) => {
  const livesAvailable = _.range(0, state.game.livesCount)
  const livesNotAvailable = _.range(0, 2 - livesAvailable.length)

  return state.game.status !== "question" ? null : (
    <div
      data-testid="life-modal"
      className={`modal ${active ? "is-active" : ""}`}
    >
      <div
        className={backgroundCss({
          backgroundImage: customerData.contentfulCustomer.backgroundImage,
        })}
      ></div>
      <div className={modalContentCss}>
        <div className="box">
          <img className={imageCss} src={LifeModalImg} alt="" />

          <div>
            <Tag className="is-life-red is-outline">
              <FormattedMessage
                id="life_modal.tag"
                values={{ name: userInfo.first_name }}
              />
            </Tag>
          </div>

          <p className="margin-top-16">
            <FormattedMessage id="life_modal.description" />
          </p>

          <div className="margin-top-24">
            {livesAvailable.map(life => (
              <i key={life} className={lifeHeartCss({ available: true })} />
            ))}
            {livesNotAvailable.map(life => (
              <i key={life} className={lifeHeartCss({ available: false })} />
            ))}
          </div>

          <div className="margin-top-32">
            <Button
              className={cx(
                "is-link",
                "is-fullwidth",
                "is-rounded",
                "is-medium",
                css`
                  height: 60px;
                `
              )}
              onClick={() => {
                socket.emit("use_life", {
                  userId: userInfo.id,
                  quizId: state.entry.id,
                  questionId: state.question.id,
                })

                dispatch({
                  type: "LIFE_USE",
                })

                dispatch({
                  type: "LIVES_MODAL_TOGGLE",
                  payload: false,
                })
              }}
            >
              life_modal.button
            </Button>
          </div>

          <p className="is-size-7 margin-top-16 margin-bottom-8">
            <FormattedMessage
              id="life_modal.decide_within"
              values={{ seconds: state.game.timeLeftToNextQuestion || 0 }}
            />
          </p>
        </div>
      </div>
    </div>
  )
}

export default LifeModal
