import React from "react"
import _ from "lodash"
import axios from "axios"
import { FormattedMessage } from "react-intl"
import mainCss, {
  columnsCss,
  positionCss,
  pointsBadgeCss,
} from "./index.styles"
import config from "~src/config"
import { Position } from "~images"
import vars from "~utils/variables"
import PointsBadge from "../points_badge"
import Button from "~components/_layout/button"

const RankingsPosition = ({ val }) => (
  <div className={columnsCss}>
    <div className="column is-narrow">
      <div className={positionCss(val.rank)}>
        <Position
          color={
            val.rank === 1
              ? "#f9bf30"
              : val.rank === 2
              ? "#bcc3c7"
              : val.rank === 3
              ? "#ff9800"
              : vars.white
          }
        />
        <p>#{val.rank}</p>
      </div>
    </div>
    <div className="column is-narrow no-padding-left-mobile no-padding-right-mobile">
      <figure className="image is-48x48">
        <img className="is-rounded" src={val.profile_pic} alt="" />
      </figure>
    </div>
    <div className="column">
      <span className="has-text-weight-bold">{val.first_name}</span>
    </div>
    <div className="column is-narrow has-text-right">
      <PointsBadge className={pointsBadgeCss} points={val.points} />
    </div>
  </div>
)

const Rankings = ({ state, dispatch, userId }) => {
  const data = state.rankings

  if (!data || !data.length) {
    return null
  }

  const last = _.last(data)
  const isUserLast = last.user_id === userId

  if (process.env.GATSBY_DISABLE_RANKINGS) {
    return <div></div>
  }

  return (
    <div className={mainCss} id="rankings">
      <h4 className="has-text-weight-bold">
        <FormattedMessage id="rankings.title" />
      </h4>
      <div className="box padding-bottom-32 padding-top-32 margin-top-16">
        <div>
          {(isUserLast ? _.initial(data) : data).map((val, i) => (
            <RankingsPosition key={i} val={val} />
          ))}
        </div>

        <Button
          className="is-fullwidth margin-top-24"
          onClick={async () => {
            try {
              const jwt = localStorage.getItem("jwt")
              const rankings = await axios(
                `${config.sodium}/api/rankings?limit=${state.ui.rankingsLimit}&customer_id=${process.env.GATSBY_CLIENT_ID}`,
                {
                  method: "get",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-JWT-TOKEN": jwt,
                  },
                }
              )

              dispatch({
                type: "RANKINGS_UPDATE",
                payload: rankings.data || [],
              })
            } catch (e) {
              console.log(e)
            }
          }}
        >
          rankings.load_more
        </Button>
      </div>
      {isUserLast ? (
        <div className="box padding-bottom-32 padding-top-32 margin-top-16">
          <RankingsPosition val={last} />
        </div>
      ) : null}
    </div>
  )
}

export default Rankings
