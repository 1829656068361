import { css, cx, keyframes } from "emotion"
import vars from "~utils/variables"

const rollOutRight = keyframes`
  0% {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }

  100% {
    transform: translateX(1000px) rotate(90deg);
    opacity: 0;
  }
`

const fadeBottomToTop = keyframes`
  0% {
    top: 100px;
    opacity: 0;
  }

  20% {
    top: 17px;
    opacity: 1;
  }

  80% {
    top: 17px;
    opacity: 1;
  }

  100% {
    top: -25px;
    opacity: 0;
  }
`

export const questionStatusCss = {
  out: css`
    width: 100%;
    top: 0px;
    z-index: 4;
    animation: ${rollOutRight} 0.5s ease;
  `,
  active: css`
    opacity: 1;
    position: relative;
    width: 100%;
    top: 0px;
    z-index: 3;
  `,
  next: css`
    opacity: 0.8;
    width: 95%;
    left: 50%;
    height: calc(100% - 10px);
    margin-left: -47.5%;
    top: 0px;
    z-index: 2;
  `,
  "after-next": css`
    opacity: 0.4;
    width: 90%;
    left: 50%;
    height: calc(100%);
    margin-left: -45%;
    top: 0px;
    z-index: 1;
  `,
}

export const questionTextCss = gameStatus =>
  cx(
    css`
      color: ${gameStatus === "user-spectator" ? vars.spectator : vars.danger};
      transition: color 0.25s ease-out;
      font-size: 1.3rem;
    `,
    "margin-bottom-24",
    "margin-top-24",
    "has-text-weight-bold"
  )

export const answerButtonCss = cx(
  css`
    color: ${vars.purple};
    height: auto;

    &:hover,
    &:focus,
    &:active {
      border-color: #dbdbdb;
      color: ${vars.purple};
    }

    > span {
      white-space: initial;
    }

    .options-percent {
      position: absolute;
      z-index: 2;
      top: 5px;
      bottom: 5px;
      left: 5px;
      right: 5px;

      > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 100px;
        transition: width 0.25s ease-out;
      }
    }

    &.is-outlined {
      .options-percent {
        > div {
          background-color: ${vars.purple};
        }
      }

      > span {
        text-shadow: 1px 1px 0px white;
        z-index: 2;
      }
    }
  `,
  "button",
  "is-fullwidth",
  "is-rounded",
  "padding-top-16",
  "padding-bottom-16",
  "margin-bottom-16"
)

export const pointsBadgeWrapperCss = cx(css`
  position: absolute;
  right: 70px;
  opacity: 0;
  top: 100px;
  animation: ${fadeBottomToTop} 3s ease 1;
`)

export default cx(
  css`
    opacity: 0;
    height: 100%;
    position: absolute;
    z-index: 0;
    transition: top 0.5s cubic-bezier(0.77, 0, 0.175, 1),
      opacity 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  `,
  "box"
)
