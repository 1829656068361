import { css, cx } from "emotion"

export default cx(
  css`
    display: flex;
    position: relative;
    margin-bottom: 40px;
  `,
  "container"
)
