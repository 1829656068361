import { css, cx } from "emotion"

export const winnerImageCss = cx(
  css`
    position: relative;
    margin-top: -70px;
    max-width: 180px;
  `,
  "margin-bottom-24"
)

export default cx("box", "margin-top-48")
