import { css, cx } from "emotion"
import vars from "~utils/variables"

export const columnsCss = cx(
  css`
    position: relative;
    border-bottom: 1px solid ${vars.greyLighter};

    &:last-child {
      border-bottom: none;
    }
  `,
  "margin-left-16-tablet",
  "margin-right-16-tablet",
  "margin-left-16-desktop",
  "margin-right-16-desktop",
  "columns",
  "is-vcentered",
  "is-mobile"
)

export default cx(css``, "has-text-left")
