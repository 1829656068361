import React from "react"
import _ from "lodash"
import { FormattedMessage, injectIntl } from "react-intl"
import moment from "moment"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride"
import slugify from "slugify"
import { window } from "browser-monads"
import config from "~src/config"
import utils from "~utils"
import vars from "~utils/variables"
import richTextOptions from "~utils/rich-text-options"
import Rankings from "../rankings"
import Modal from "~components/_layout/modal"
import Button from "~components/_layout/button"
import Tag from "~components/_layout/tag"
import Invite from "~components/invite"
import HelpImage from "~images/help.svg"
import IdeaImage from "~images/idea.svg"
import { css } from "emotion"
import {
  logoCss,
  countdownCss,
  prizeCss,
  brandingCss,
  profilePicCss,
  userNameCss,
  userBoxColumnsCss,
} from "./index.styles"

const AwaitingStart = ({ state, dispatch, userInfo, customerData, intl }) => {
  const [isRedeemModalActive, toggleRedeemModal] = React.useState(false)
  const [isExtraLivesModalActive, toggleExtraLivesModal] = React.useState(false)
  const [isInviteModalOpen, toggleInviteModal] = React.useState(false)
  const [showJoyride, setShowJoyride] = React.useState(false)
  const [joyrideStep, setJoyrideStep] = React.useState(0)
  const [isJoyrideRunning, setIsJoyrideRunning] = React.useState(true)

  const joyrideSteps = React.useRef(
    _.map(customerData.contentfulCustomer.tour || [], step => ({
      target: `#${slugify(step.element.toLowerCase())}`,
      content: step.text,
      disableBeacon: true,
      placement: "auto",
    }))
  )

  React.useEffect(() => {
    if (
      !utils._localStorage.get("joyride_seen") ||
      window.location.search.includes("tutorial")
    ) {
      setShowJoyride(true)

      utils._localStorage.set("joyride_seen", "true")
    }
  }, [])

  return state.game.status !== "awaiting_start" ? null : (
    <div data-testid="awaiting-start">
      {showJoyride && (
        <Joyride
          steps={joyrideSteps.current}
          stepIndex={joyrideStep}
          run={isJoyrideRunning}
          callback={data => {
            const { action, index, status, type } = data

            if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
              // Update state to advance the tour
              setJoyrideStep(index + (action === ACTIONS.PREV ? -1 : 1))
            } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
              // Need to set our running state to false, so we can restart if we click start again.
              setIsJoyrideRunning(false)
            }
          }}
          locale={{
            back: intl.formatMessage({ id: "awaiting_start.tour.back" }),
            close: intl.formatMessage({ id: "awaiting_start.tour.close" }),
            last: intl.formatMessage({ id: "awaiting_start.tour.last" }),
            next: intl.formatMessage({ id: "awaiting_start.tour.next" }),
            skip: intl.formatMessage({ id: "awaiting_start.tour.skip" }),
          }}
          styles={{
            options: {
              // arrowColor: "#ff",
              backgroundColor: "#fff",
              overlayColor: "rgba(79, 26, 0, 0.4)",
              primaryColor: vars.primary,
              textColor: "#004a14",
              zIndex: 10000,
            },
          }}
          continuous
          scrollToFirstStep
          showProgress
          showSkipButton
          debug
        />
      )}

      <div
        className={logoCss({
          width: customerData.contentfulCustomer.logoWidthAwaitingStartSection,
        })}
      >
        <img src={customerData.contentfulCustomer.logo.resize.src} alt="Logo" />
      </div>

      {!_.isEmpty(state.data) && (
        <>
          {state.entry.id === "674" ||
          window.location.search.includes("endtest") ? (
            <>
              <h1 className={countdownCss}>Grazie!</h1>

              <p style={{ maxWidth: 500, margin: "0 auto" }}>
                Grazie per aver partecipato a Bake Off QuizTime. Fai parte dei
                vincitori? Ricordati di controllare la tua casella email durante
                le prossime settimane!
              </p>
            </>
          ) : (
            <>
              <Tag className="is-white-transparent" id="next-game">
                <FormattedMessage id="awaiting_start.next" />:{" "}
                {moment(state.data.startsAt).calendar()}
              </Tag>

              <h1 className={countdownCss} id="countdown">
                <span>
                  {("0" + state.game.timeLeftToStart.days).slice(-2)}
                  <span className="is-size-7 is-uppercase">
                    <FormattedMessage id="awaiting_start.days" />
                  </span>
                </span>
                :
                <span>
                  {("0" + state.game.timeLeftToStart.hours).slice(-2)}
                  <span className="is-size-7 is-uppercase">
                    <FormattedMessage id="awaiting_start.hours" />
                  </span>
                </span>
                :
                <span>
                  {("0" + state.game.timeLeftToStart.minutes).slice(-2)}
                  <span className="is-size-7 is-uppercase">
                    <FormattedMessage id="awaiting_start.minutes" />
                  </span>
                </span>
                :
                <span>
                  {("0" + state.game.timeLeftToStart.seconds).slice(-2)}
                  <span className="is-size-7 is-uppercase">
                    <FormattedMessage id="awaiting_start.seconds" />
                  </span>
                </span>
              </h1>

              <div
                className={prizeCss({
                  prizeLogo:
                    customerData.contentfulCustomer.prizeLogo.resize.src,
                  prizeBoxBackgroundColor:
                    customerData.contentfulCustomer.prizeBoxBackgroundColor,
                })}
                id="prize"
              >
                {utils.getPrize({ state, intl, prize: state.data.prize })}
              </div>

              <p className="is-size-7">
                <FormattedMessage id="awaiting_start.in" />{" "}
                {customerData.contentfulCustomer.prizeName}.{" "}
                <a
                  className="has-text-white is-underlined"
                  onClick={() => toggleRedeemModal(true)}
                >
                  <FormattedMessage id="awaiting_start.how_to_redeem" />
                </a>
              </p>
            </>
          )}

          {_.get(
            customerData,
            "contentfulCustomer.childContentfulCustomerBrandingRichTextNode.json"
          ) && (
            <div className={brandingCss}>
              {documentToReactComponents(
                customerData.contentfulCustomer
                  .childContentfulCustomerBrandingRichTextNode.json,
                richTextOptions
              )}
            </div>
          )}

          <div className="box no-padding margin-top-48 no-margin-bottom">
            <img className={profilePicCss} src={userInfo.profile_pic} alt="" />

            <p className={userNameCss}>{userInfo.first_name}</p>

            <div className={userBoxColumnsCss}>
              <div className="column padding-32" id="winnings">
                <h6 className="is-size-7 has-text-primary is-uppercase">
                  <FormattedMessage id="awaiting_start.wins" />
                </h6>

                <h3 className="has-text-gold margin-top-8 margin-bottom-8 has-text-weight-bold">
                  {utils.getPrize({
                    state,
                    intl,
                    prize: userInfo.total_money_won || 0,
                  })}
                </h3>

                <Button
                  className="is-rounded is-small is-size-7 is-uppercase has-text-weight-bold has-text-primary"
                  onClick={() => toggleRedeemModal(true)}
                >
                  awaiting_start.info
                </Button>
              </div>

              <div className="column padding-32" id="extra-lives">
                <h6 className="is-size-7 has-text-primary is-uppercase">
                  <FormattedMessage id="awaiting_start.extra_lives" />
                </h6>

                <h3 className="has-text-life-red margin-top-8 margin-bottom-8 has-text-weight-bold">
                  {userInfo.lives_count} <i className="fa fa-heart" />
                </h3>

                <Button
                  className="is-rounded is-small is-size-7 is-uppercase has-text-weight-bold has-text-primary"
                  onClick={() => toggleExtraLivesModal(true)}
                >
                  awaiting_start.win_lives
                </Button>
              </div>
            </div>
          </div>

          <div id="how-to-earn-extra-lives">
            <div
              className="box has-text-left padding-32-desktop padding-32-tablet padding-24-mobile margin-top-48 no-margin-bottom is-cursor-pointer"
              onClick={() => toggleInviteModal(true)}
            >
              <div className="columns is-vcentered is-mobile">
                <div className="column">
                  <h4 className="has-text-primary has-text-weight-bold">
                    <FormattedMessage id="awaiting_start.invite_friend" />
                  </h4>

                  <p>
                    <FormattedMessage id="awaiting_start.invite_friend.description" />
                  </p>

                  <Tag
                    element="p"
                    className="is-rounded is-medium is-life-red margin-top-16"
                  >
                    1
                    <i className="far fa-heart margin-left-8 margin-right-8"></i>{" "}
                    /{" "}
                    {intl.formatMessage({
                      id: "awaiting_start.friends_invited",
                    })}
                  </Tag>
                </div>

                <div className="column has-text-right no-padding-left-mobile">
                  <img
                    src={HelpImage}
                    alt=""
                    className={css`
                      max-width: 130px;
                    `}
                  />
                </div>
              </div>
            </div>

            <a
              href={`${config.oxide}/track/click/aFv4dS3/${userInfo.hashid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="box has-text-left padding-32-desktop padding-32-tablet padding-24-mobile margin-top-16 margin-bottom-48">
                <div className="columns is-vcentered is-mobile">
                  <div className="column">
                    <h4 className="has-text-primary has-text-weight-bold">
                      <FormattedMessage id="awaiting_start.gaming_platform_subscribe" />
                    </h4>

                    <p>
                      <FormattedMessage id="awaiting_start.gaming_platform_subscribe.description" />
                    </p>

                    <Tag
                      element="p"
                      className="is-rounded is-medium is-life-red margin-top-16"
                    >
                      1
                      <i className="far fa-heart margin-left-8 margin-right-8"></i>{" "}
                      /{" "}
                      {intl.formatMessage({ id: "awaiting_start.immediately" })}
                    </Tag>
                  </div>

                  <div className="column has-text-right no-padding-left-mobile">
                    <img
                      src={IdeaImage}
                      alt=""
                      className={css`
                        max-width: 130px;
                      `}
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <Rankings state={state} dispatch={dispatch} userId={userInfo.id} />
        </>
      )}

      <Modal
        title="redeem.title"
        active={isRedeemModalActive}
        toggle={toggleRedeemModal}
      >
        <div className="content has-text-left has-text-black">
          {documentToReactComponents(
            customerData.contentfulCustomer
              .childContentfulCustomerRedeemInstructionsRichTextNode.json,
            richTextOptions
          )}
        </div>
      </Modal>

      <Modal
        title="extra_lives_modal.title"
        active={isExtraLivesModalActive}
        toggle={toggleExtraLivesModal}
      >
        <div className="content has-text-left has-text-black">
          {documentToReactComponents(
            customerData.contentfulCustomer
              .childContentfulCustomerExtraLivesInstructionsRichTextNode.json,
            richTextOptions
          )}
        </div>
      </Modal>

      <Modal
        active={isInviteModalOpen}
        toggle={toggleInviteModal}
        hasTopRightCloseButton
        layout="blank"
      >
        <Invite {...{ userInfo, domain: config.shortLink }} />
      </Modal>
    </div>
  )
}

export default injectIntl(AwaitingStart)
