import React from "react"
import _ from "lodash"
import io from "socket.io-client"
import config from "~src/config"
import { getNextQuiz } from "~actions"
import Layout from "~components/_layout"
import Loader from "~components/_layout/loader"
import Tag from "~components/_layout/tag"
import SEO from "~components/_layout/seo"
import SocketError from "~components/socket_error"
import FloatingNotification from "~components/floating_notification"
import { useReducer } from "reinspect"
import ThankYouPage from "./components/thankyou"
import AwaitingStart from "./components/awaiting_start"
import Questions from "./components/questions"
import Question from "./components/question"
import LifeModal from "./components/life_modal"
import GameOver from "./components/game_over"
import Debug from "./components/debug"
import initialState from "./state"
import reducer from "./reducer"
import action from "./action"
import utils from "~utils"
import mainCss, { onlineUsersCss } from "./index.styles"

const socket = io(config.sodium, {
  reconnectionDelay: 1000,
  reconnection: true,
  reconnectionAttempts: 10,
  transports: ["websocket"],
  agent: false,
  upgrade: false,
  rejectUnauthorized: false,
})

const Quiz = ({ customerData, userInfo }) => {
  const [state, dispatch] = useReducer(reducer, initialState, "game-state")

  const init = async () => {
    const jwt = utils._localStorage.get("jwt")

    let nextQuiz

    try {
      nextQuiz = await getNextQuiz(userInfo.id)

      dispatch({
        type: "ENTRY_UPDATE",
        payload: {
          id: nextQuiz.id,
          hashId: nextQuiz.hashid,
        },
      })

      dispatch({
        type: "LIVES_COUNT_UPDATE",
        payload: userInfo.lives_count > 1 ? 2 : userInfo.lives_count,
      })
    } catch (e) {
      return
    }

    socket.emit("join_quiz", {
      quizHashId: nextQuiz.hashid,
      accessToken: jwt,
      userId: userInfo.id,
    })

    socket.on("reconnect", function() {
      console.log("You have been reconnected")

      socket.emit("join_quiz", {
        quizHashId: nextQuiz.hashid,
        accessToken: jwt,
        userId: userInfo.id,
      })
    })

    _.forEach(
      [
        "time_left_to_start",
        "seconds_left_to_answer",
        "answered",
        "time_left_to_next_question",
        "quiz_end",
        "spectator_mode",
        "life_granted",
      ],
      event =>
        socket.on(event, data =>
          action(state, userInfo.id, dispatch)[event](data)
        )
    )
  }

  React.useEffect(() => {
    init()

    return () => {
      _.forEach(
        [
          "time_left_to_start",
          "seconds_left_to_answer",
          "answered",
          "time_left_to_next_question",
          "quiz_end",
          "spectator_mode",
          "life_granted",
        ],
        event => socket.off(event)
      )
    }
  }, [])

  React.useEffect(() => {
    _.forEach(["online_users", "quiz_info"], event =>
      socket.on(event, data =>
        action(state, userInfo.id, dispatch)[event](data)
      )
    )

    return () => {
      _.forEach(["online_users", "quiz_info"], event => socket.off(event))
    }
  }, [state.entry])

  React.useEffect(() => {
    _.forEach(
      ["question_result", "time_left_to_next_question", "spectator_mode"],
      event =>
        socket.on(event, data =>
          action(state, userInfo.id, dispatch)[event](data)
        )
    )

    return () => {
      _.forEach(
        ["question_result", "time_left_to_next_question", "spectator_mode"],
        event => socket.off(event)
      )
    }
  }, [state.question])

  React.useEffect(() => {
    _.forEach(["question", "time_left_to_next_question"], event =>
      socket.on(event, data =>
        action(state, userInfo.id, dispatch)[event](data)
      )
    )

    return () => {
      _.forEach(["question", "time_left_to_next_question"], event =>
        socket.off(event)
      )
    }
  }, [state.data])

  React.useEffect(() => {
    _.forEach(
      ["time_left_to_next_question", "spectator_mode", "question_result"],
      event =>
        socket.on(event, data =>
          action(state, userInfo.id, dispatch)[event](data)
        )
    )

    return () => {
      _.forEach(
        ["time_left_to_next_question", "spectator_mode", "question_result"],
        event => socket.off(event)
      )
    }
  }, [state.game])

  return (
    <Layout
      styleProps={{
        backgroundColor: customerData.contentfulCustomer.backgroundColor,
        backgroundImage: customerData.contentfulCustomer.backgroundImage,
      }}
      gameStatus={utils.getGameStatus(state)}
    >
      <SEO title={`Il nuovo QuizTime di Bake Off Italia`} />

      {state.ui.loading && <Loader fullScreen />}

      <div className={mainCss}>
        <Tag className={onlineUsersCss}>
          <i className="fa fa-user margin-right-8" />
          <span>{process.env.GATSBY_ONLINE_USERS || state.onlineUsers}</span>
        </Tag>

        <div className="columns">
          <div className="column is-12-tablet is-8-desktop is-offset-2-desktop is-6-widescreen is-offset-3-widescreen">
            <ThankYouPage {...{ customerData }} />

            {/* <AwaitingStart {...{ state, dispatch, userInfo, customerData }} /> */}

            <Questions {...{ state }}>
              {_.range(0, state.data.numberOfQuestions).map((question, i) => (
                <Question
                  key={i}
                  {...{
                    state,
                    i,
                    userInfo,
                    dispatch,
                    socket,
                    status: state.questionClasses[i],
                  }}
                />
              ))}
            </Questions>

            <LifeModal
              {...{
                state,
                userInfo,
                customerData,
                dispatch,
                socket,
                active: state.ui.isLivesModalOpen,
              }}
            />
            <GameOver {...{ state, dispatch, userInfo, customerData }} />

            {process.env.GATSBY_TEST_ENV &&
            process.env.GATSBY_TEST_ENV === "PRODUCTION" ? null : (
              <Debug {...{ state, userInfo, customerData }} />
            )}

            {state.ui.socketError && state.game.status !== "end" && (
              <SocketError />
            )}

            {state.ui.loadingResults && (
              <FloatingNotification
                title="awaiting_results.title"
                subtitle="awaiting_results.subtitle"
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Quiz
