export default {
  onlineUsers: 0,
  entry: {
    id: null,
    hashId: null,
  },
  ui: {
    loading: true,
    error: null,
    isLivesModalOpen: false,
    socketError: false,
    loadingResults: false,
    answered: false,
    rankingsLimit: 5,
  },
  game: {
    status: null,
    spectatorMode: false,
    isUserWinner: false,
    userEarnedPoints: 0,
    prizePerWinner: 0,
    winners: [],
    timeLeftToStart: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    timeLeftToAnswer: null,
    timeLeftToNextQuestion: null,
    livesCount: 0,
  },
  data: {},
  question: {
    id: null,
    order: null,
    text: null,
    answerId: null,
    correctAnswerId: null,
    options: [],
    type: null,
    isLast: false,
  },
  questionClasses: {},
  rankings: [],
}
