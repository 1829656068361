import axios from "axios"
import _ from "lodash"
import config from "~src/config"

export const getNextQuiz = userId => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios(
        `${config.sodium}/api/quizzes/next?user_id=${userId}&customer_id=${
          process.env.GATSBY_CLIENT_ID
        }${
          process.env.GATSBY_TEST_ENV &&
          process.env.GATSBY_TEST_ENV === "PRODUCTION"
            ? ""
            : "&test=true"
        }`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      resolve(response.data)
    } catch (e) {
      reject()
    }
  })
}
