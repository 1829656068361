import { cx, css } from "emotion"
import vars from "~utils/variables"

export default cx(css`
  left: 50%;
  margin-top: -10px;
  margin-left: -20px;

  circle {
    transition: all 1s linear;
  }

  text {
    font-size: 17px;
    font-family: Helvetica, sans-serif;
    font-weight: 800;
    fill: ${vars.white};
  }

  &.inactive {
    text {
      fill: rgba(255, 255, 255, 0.3);
    }
  }
`)
