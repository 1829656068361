import React from "react"
import {
  logoCss,
  countdownCss,
  brandingCss,
} from "../awaiting_start/index.styles"
import richTextOptions from "~utils/rich-text-options"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export default ({ customerData }) => {
  return (
    <div>
      <div
        className={logoCss({
          width: customerData.contentfulCustomer.logoWidthAwaitingStartSection,
        })}
      >
        <img src={customerData.contentfulCustomer.logo.resize.src} alt="Logo" />
      </div>

      <h1 className={countdownCss}>Grazie!</h1>

      <p style={{ maxWidth: 500, margin: "0 auto" }}>
        Grazie per aver partecipato a Bake Off QuizTime. Fai parte dei
        vincitori? Ricordati di controllare la tua casella email durante le
        prossime settimane!
      </p>

      {_.get(
        customerData,
        "contentfulCustomer.childContentfulCustomerBrandingRichTextNode.json"
      ) && (
        <div className={brandingCss}>
          {documentToReactComponents(
            customerData.contentfulCustomer
              .childContentfulCustomerBrandingRichTextNode.json,
            richTextOptions
          )}
        </div>
      )}
    </div>
  )
}
