import React from "react"
import { FormattedMessage } from "react-intl"
import _ from "lodash"
import { AuthConsumer } from "~contexts/auth"
import utils from "../../utils"
import validation from "./validation"

const Login = ({ onLoginSuccess = () => {} }) => {
  const initialState = {
    provider: "emailpassword",
    form: {
      email: "",
      password: "",
    },
    loading: true,
    validated: false,
    error: false,
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_AS_VALIDATED":
        return {
          ...state,
          validated: true,
          error: false,
        }
      case "SET_ERROR_STATE":
        return {
          ...state,
          error: action.payload,
        }
      case "SWITCH_MODE":
        return {
          ...state,
          provider: action.payload,
        }
      case "EDIT_LOGIN_DATA":
        return {
          ...state,
          form: {
            ...state.form,
            ...action.payload,
          },
        }
      default:
        return state
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState)

  const isValid = field => {
    let obj = state.form

    return validation(obj)[field] && validation(obj)[field]()
  }

  return (
    <AuthConsumer>
      {({ login, isAuth, isLoading }) =>
        isAuth || isLoading ? null : (
          <div>
            <form
              onSubmit={async e => {
                e.preventDefault()

                dispatch({
                  type: "SET_ERROR_STATE",
                  payload: false,
                })

                dispatch({
                  type: "SET_AS_VALIDATED",
                })

                if (
                  !utils.validate(
                    validation,
                    _.merge(state.form, { provider: state.provider })
                  )
                ) {
                  return
                }

                try {
                  await login({
                    provider: "email",
                    credentials: state.form,
                  })

                  onLoginSuccess()
                } catch (e) {
                  dispatch({
                    type: "SET_ERROR_STATE",
                    payload: true,
                  })
                }
              }}
            >
              {state.error && (
                <div className="notification is-danger margin-bottom-24">
                  <FormattedMessage id="login.server_error" />
                </div>
              )}

              <div className="field">
                <div className="control">
                  <input
                    className={`input ${
                      !isValid("email") && state.validated ? "is-danger" : ""
                    }`}
                    type="email"
                    placeholder="E-mail"
                    data-testid="input-email"
                    value={state.form.email}
                    onChange={e =>
                      dispatch({
                        type: "EDIT_LOGIN_DATA",
                        payload: {
                          email: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <input
                    className={`input ${
                      !isValid("password") && state.validated ? "is-danger" : ""
                    }`}
                    type="password"
                    placeholder="Password"
                    data-testid="input-password"
                    value={state.form.password}
                    onChange={e =>
                      dispatch({
                        type: "EDIT_LOGIN_DATA",
                        payload: {
                          password: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button
                    type="submit"
                    className="button is-success is-medium is-fullwidth"
                    data-testid="login-submit"
                  >
                    <FormattedMessage id="login.signin_with_email_button" />
                  </button>
                </div>
              </div>
            </form>

            {/* <p className="margin-top-16 margin-bottom-16">
              <FormattedMessage id="login.or" />
            </p>

            <button
              type="button"
              className="button is-link is-fullwidth"
              onClick={async () => {
                try {
                  await login({
                    provider: "facebook",
                  })

                  onLoginSuccess()
                } catch (e) {}
              }}
            >
              <FormattedMessage id="login.signin_with_facebook_button" />
            </button> */}
          </div>
        )
      }
    </AuthConsumer>
  )
}

export default Login
