import _ from "lodash"
import axios from "axios"
import moment from "moment"
import utils from "~utils"
import config from "~src/config"

export default (state, userId, dispatch) => {
  return {
    quiz_info: async data => {
      utils._localStorage.set(`last_updated`, moment().format())

      let payload

      payload = _.pick(data.payload, [
        "currency_name",
        "custom_prize_name",
        "has_real_currency",
        "interval_between_questions",
        "number_of_questions",
        "prize",
        "quiz_mode",
        "redeem_instructions",
        "starts_at",
      ])

      payload = _.mapKeys(payload, (val, key) => _.camelCase(key))

      dispatch({
        type: "LOADING_TOGGLE",
        payload: false,
      })

      dispatch({
        type: "GAME_DATA_UPDATE",
        payload,
      })

      if (moment().isBefore(payload.startsAt)) {
        dispatch({
          type: "GAME_STATUS_UPDATE",
          payload: "awaiting_start",
        })

        try {
          const jwt = localStorage.getItem("jwt")
          const rankings = await axios(
            `${config.sodium}/api/rankings?limit=${state.ui.rankingsLimit}&id=${state.entry.id}&customer_id=${process.env.GATSBY_CLIENT_ID}`,
            {
              method: "get",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-JWT-TOKEN": jwt,
              },
            }
          )

          dispatch({
            type: "RANKINGS_UPDATE",
            payload: rankings.data || [],
          })
        } catch (e) {
          console.log(e)
        }
      }

      setInterval(() => {
        const lastUpdated = utils._localStorage.get(`last_updated`)

        if (!lastUpdated) {
          return
        }

        const diff = moment().diff(lastUpdated, "seconds")

        if (diff > 4) {
          dispatch({
            type: "SOCKET_ERROR_TOGGLE",
            payload: true,
          })
        } else {
          dispatch({
            type: "SOCKET_ERROR_TOGGLE",
            payload: false,
          })
        }
      }, 1000)
    },
    time_left_to_start: data => {
      utils._localStorage.set(`last_updated`, moment().format())

      const { payload } = data

      if (
        payload.days === 0 &&
        payload.hours === 0 &&
        payload.minutes === 0 &&
        payload.seconds === 0
      ) {
        dispatch({
          type: "LOADING_TOGGLE",
          payload: true,
        })
      }

      dispatch({
        type: "TIME_LEFT_TO_START_UPDATE",
        payload,
      })
    },
    online_users: data => {
      utils._localStorage.set(`last_updated`, moment().format())

      dispatch({
        type: "ONLINE_USERS_UPDATE",
        payload: data.payload,
      })
    },
    question: data => {
      utils._localStorage.set(`last_updated`, moment().format())

      const { payload } = data

      dispatch({
        type: "LOADING_TOGGLE",
        payload: false,
      })

      dispatch({
        type: "ANSWERED_TOGGLE",
        payload: false,
      })

      dispatch({
        type: "GAME_STATUS_UPDATE",
        payload: "question",
      })

      dispatch({
        type: "TIME_LEFT_TO_NEXT_QUESTION_UPDATE",
        payload: null,
      })

      let questionClasses = {}

      _.forEach(_.range(0, state.data.numberOfQuestions), question => {
        let questionIndex = payload.order - 1

        if (question < questionIndex) {
          questionClasses[question] = "out"
        } else if (question === questionIndex) {
          questionClasses[question] = "active"
        } else if (question === questionIndex + 1) {
          questionClasses[question] = "next"
        } else if (question === questionIndex + 2) {
          questionClasses[question] = "after-next"
        } else {
          questionClasses[question] = null
        }
      })

      dispatch({
        type: "QUESTION_CLASSES_UPDATE",
        payload: questionClasses,
      })

      dispatch({
        type: "QUESTION_UPDATE",
        payload: {
          id: payload.id,
          order: payload.order,
          text: payload.text,
          options: payload.options,
          answerId: null,
          correctAnswerId: null,
          type: payload.type,
          isLast: payload.is_last,
        },
      })
    },
    question_result: data => {
      utils._localStorage.set(`last_updated`, moment().format())

      const { correctAnswerId, count } = data.payload

      dispatch({
        type: "QUESTION_RESULT_SHOW",
        payload: {
          correctAnswerId,
          options: _.map(_.clone(state.question.options), option => {
            option.count = count[option.id] || 0

            return option
          }),
        },
      })

      if (state.question.answerId === correctAnswerId) {
        dispatch({
          type: "EARNED_POINTS_UPDATE",
          payload:
            state.game.userEarnedPoints +
            config.quizRules.points(
              utils.getGameStatus(state) === "user-spectator"
            )[state.question.type.toLowerCase()],
        })
      } else {
        if (state.game.spectatorMode) {
          return
        }

        dispatch({
          type: "SPECTATOR_SET",
          payload: true,
        })
      }
    },
    seconds_left_to_answer: data => {
      utils._localStorage.set(`last_updated`, moment().format())

      dispatch({
        type: "SECONDS_LEFT_TO_ANSWER_UPDATE",
        payload: moment.duration(data.payload, "seconds").seconds(),
      })
    },
    answered: data => {
      utils._localStorage.set(`last_updated`, moment().format())

      dispatch({
        type: "QUESTION_MARK_AS_ANSWERED",
        payload: data.payload.answer,
      })
    },
    time_left_to_next_question: data => {
      utils._localStorage.set(`last_updated`, moment().format())

      const { seconds } = data.payload

      if (seconds <= parseInt(state.data.intervalBetweenQuestions * 0.8)) {
        dispatch({
          type: "TIME_LEFT_TO_NEXT_QUESTION_UPDATE",
          payload: seconds,
        })
      }

      if (
        seconds === parseInt(state.data.intervalBetweenQuestions * 0.8) &&
        state.game.livesCount > 0 &&
        state.question.answerId !== state.question.correctAnswerId &&
        state.game.spectatorMode &&
        state.game.spectatorQuestion === state.question.id
      ) {
        dispatch({
          type: "LIVES_MODAL_TOGGLE",
          payload: true,
        })
      }

      if (
        seconds === parseInt(state.data.intervalBetweenQuestions * 0.8) &&
        state.question.isLast
      ) {
        dispatch({
          type: "LOADING_RESULTS_TOGGLE",
          payload: true,
        })
      }

      if (seconds <= 0) {
        dispatch({
          type: "LIVES_MODAL_TOGGLE",
          payload: false,
        })
      }
    },
    quiz_end: data => {
      utils._localStorage.set(`last_updated`, moment().format())

      dispatch({
        type: "GAME_STATUS_UPDATE",
        payload: "end",
      })

      dispatch({
        type: "LOADING_RESULTS_TOGGLE",
        payload: false,
      })

      dispatch({
        type: "WINNERS_SET",
        payload: {
          winners: data.payload.winners,
          isUserWinner: !!_.find(
            data.payload.winners,
            winner => winner.id === userId
          ),
        },
      })

      dispatch({
        type: "PRIZE_PER_WINNER_SET",
        payload: data.payload.prizePerWinner,
      })

      dispatch({
        type: "RANKINGS_UPDATE",
        payload: data.payload.rankings || [],
      })
    },
    spectator_mode: () => {
      utils._localStorage.set(`last_updated`, moment().format())

      if (state.game.spectatorMode) {
        return
      }

      dispatch({
        type: "SPECTATOR_SET",
        payload: true,
      })
    },
    life_granted: () => {
      utils._localStorage.set(`last_updated`, moment().format())

      dispatch({
        type: "SPECTATOR_SET",
        payload: false,
      })
    },
  }
}
