import React from "react"
import { Link, graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Quiz from "../quiz"
import Layout from "~components/_layout"
import SEO from "~components/_layout/seo"
import Login from "~components/login"
import { logoCss } from "./index.styles"
import { AuthConsumer } from "~contexts/auth"

export const HomeComponent = ({ customerData }) => (
  <>
    <SEO
      title={`Il nuovo QuizTime di Bake Off Italia`}
      description={`Gioca insieme ad altre migliaia di persone ed aggiudicati i 1000€ di buoni Amazon in palio ad ogni sessione. Ogni venerdì alle 21.10 su Real Time."`}
      image={`https://www.bakeoffquiztime.it/img/ogimage.png`}
    />

    <div className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-4-desktop is-offset-4-desktop has-text-centered">
            <div
              className={logoCss({
                width:
                  customerData.contentfulCustomer.logoWidthAwaitingStartSection,
              })}
            >
              <img
                src={customerData.contentfulCustomer.logo.resize.src}
                alt="Logo"
              />
            </div>

            <div className="box">
              <Login />

              <p className="margin-top-24">
                <Link to="/register">
                  <FormattedMessage id="login_page.not_registered" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

const IndexPage = ({ data }) => (
  <AuthConsumer>
    {({ isAuth, isLoading, getUserInfo }) =>
      isLoading ? null : !isAuth ? (
        <Layout
          styleProps={{
            backgroundColor: data.contentfulCustomer.backgroundColor,
            backgroundImage: data.contentfulCustomer.backgroundImage,
            minHeight100: true,
          }}
          showHeader={false}
        >
          <HomeComponent customerData={data} />
        </Layout>
      ) : (
        <Quiz customerData={data} userInfo={getUserInfo()} />
      )
    }
  </AuthConsumer>
)

export default IndexPage

export const pageQuery = graphql`
  query($customerId: Int) {
    contentfulCustomer(customerId: { eq: $customerId }) {
      title
      domain
      logo {
        resize(width: 300, quality: 100) {
          src
        }
      }
      logoWidthAwaitingStartSection
      backgroundColor
      backgroundImage {
        file {
          url
        }
      }
      childContentfulCustomerBrandingRichTextNode {
        json
      }
      prizeLogo {
        resize(width: 100, quality: 100) {
          src
        }
      }
      prizeBoxBackgroundColor
      prizeName
      childContentfulCustomerRedeemInstructionsRichTextNode {
        json
      }
      childContentfulCustomerWinnerInstructionsRichTextNode {
        json
      }
      childContentfulCustomerExtraLivesInstructionsRichTextNode {
        json
      }
      tour {
        element
        text
      }
      footer
    }
  }
`
