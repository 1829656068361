import React from "react"
import { cx } from "emotion"
import Points from "~images/points.svg"
import mainCss, { iconCss } from "./index.styles"

const PointsBadge = ({ points = 0, className }) => (
  <div className={cx(mainCss, className)}>
    <div className={iconCss}>
      <img src={Points} alt="" />
    </div>
    +{points}
  </div>
)

export default PointsBadge
