import React from "react"
import CountdownCircle from "~components/countdown_circle"
import mainCss from "./index.styles"

const Questions = ({ state, children }) =>
  state.game.status !== "question" ? null : (
    <>
      <div
        className="margin-bottom-24"
        data-testid={`question-${state.question.order}-active`}
      >
        <CountdownCircle
          seconds={
            state.game.timeLeftToNextQuestion && !state.question.isLast
              ? state.game.timeLeftToNextQuestion
              : state.game.timeLeftToAnswer
          }
        />
      </div>

      <div className={mainCss}>{children}</div>
    </>
  )

export default Questions
