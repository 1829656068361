import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { window } from "browser-monads"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import richTextOptions from "~utils/rich-text-options"
import Button from "~components/_layout/button"
import Modal from "~components/_layout/modal"
import Winners from "../winners"
import WinnerImage from "~images/winner.png"
import PointsImage from "~images/points.svg"
import mainCss, { winnerImageCss } from "./index.styles"
import utils from "~utils"

const GameOver = ({ state, dispatch, userInfo, customerData, intl }) => {
  const [isWinnerModalOpen, toggleWinnerModal] = React.useState(false)

  return state.game.status !== "end" ? null : (
    <>
      <div
        className={mainCss}
        data-testid={state.game.isUserWinner ? "you-won" : "you-lost"}
      >
        <img className={winnerImageCss} src={WinnerImage} alt="" />

        <h4
          className={`margin-bottom-16 ${
            state.game.isUserWinner ? "has-text-success" : "has-text-danger"
          }`}
        >
          <FormattedMessage
            id={`game_over.you_${
              state.game.isUserWinner ? "won" : "lost"
            }_title`}
            values={{
              name: userInfo.first_name,
            }}
          />
        </h4>

        <p className="text-primary">
          <FormattedMessage
            id={`game_over.you_${
              state.game.isUserWinner ? "won" : "lost"
            }_subtitle`}
            values={{
              winnersCount: state.game.winners.length,
            }}
          />
        </p>

        <div className="columns is-mobile margin-top-24 has-text-weight-bold">
          {state.game.isUserWinner && (
            <div className="column">
              <p className="is-size-7 is-uppercase">
                <FormattedMessage id="game_over.you_won" />
              </p>

              <p
                className={`is-size-3 ${
                  state.game.isUserWinner
                    ? "has-text-warning"
                    : "has-text-danger"
                }`}
              >
                {utils.getPrize({
                  intl,
                  state,
                  prize: state.game.isUserWinner ? undefined : 0,
                })}
              </p>

              <Button
                className="is-rounded is-small is-uppercase has-text-info margin-top-16"
                onClick={() => toggleWinnerModal(true)}
              >
                game_over.info
              </Button>
            </div>
          )}

          <div className="column">
            <p className="is-size-7 is-uppercase is-flex is-justified-center is-aligned-center">
              <FormattedMessage id="game_over.points" />
            </p>

            <p className="is-size-3 has-text-success is-uppercase is-flex is-justified-center is-aligned-center">
              <span>{state.game.userEarnedPoints || 0}</span>
              <img
                className="image is-32x32 margin-left-8"
                src={PointsImage}
                alt=""
              />
            </p>

            <Button
              className="is-rounded is-small is-uppercase has-text-info margin-top-16"
              onClick={() => {
                dispatch({
                  type: "GAME_STATUS_UPDATE",
                  payload: "awaiting_start",
                })

                window.location.href = "/#rankings"
              }}
            >
              game_over.rankings
            </Button>
          </div>
        </div>

        <Button
          className="is-secondary is-fullwidth is-rounded margin-top-24"
          onClick={() => {
            window.location.href = "/"
          }}
        >
          game_over.back_to_home
        </Button>
      </div>

      <Winners
        data={state.game.winners}
        prize={utils.getPrize({
          intl,
          state,
        })}
      />

      <Modal
        title="redeem.title"
        active={isWinnerModalOpen}
        toggle={toggleWinnerModal}
      >
        <div className="content has-text-left has-text-black">
          {documentToReactComponents(
            customerData.contentfulCustomer
              .childContentfulCustomerWinnerInstructionsRichTextNode.json,
            Object.assign(richTextOptions, {
              renderText: text =>
                text.replace("{prizeInt}", parseInt(state.game.prizePerWinner)),
            })
          )}
        </div>
      </Modal>
    </>
  )
}

export default injectIntl(GameOver)
