import { css, cx } from "emotion"
import vars from "~utils/variables"

export const columnsCss = cx(
  css`
    position: relative;
    border-bottom: 1px solid ${vars.greyLighter};

    &:last-child {
      border-bottom: none;
    }
  `,
  "margin-left-16-tablet",
  "margin-right-16-tablet",
  "margin-left-16-desktop",
  "margin-right-16-desktop",
  "columns",
  "is-vcentered",
  "is-mobile"
)

export const positionCss = position =>
  cx(
    css`
      color: ${position > 3 ? vars.black : vars.white};

      > svg {
        position: relative;
      }

      > p {
        position: absolute;
        top: 1.3rem;
        left: 1.4rem;
        font-weight: ${position > 3 ? 400 : 700};
      }
    `,
    "padding-right-8"
  )

export const pointsBadgeCss = cx(css`
  right: 10px;
  top: 50%;
  margin-top: -11px;
`)

export default cx(css``, "has-text-left")
