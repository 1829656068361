import { cx, css } from "emotion"

export default cx(
  css`
    position: fixed;
    width: 300px;
    top: 30px;
    left: 50%;
    margin-left: -150px;
    opacity: 0.95;
    z-index: 20;
  `,
  "notification",
  "is-info",
  "has-text-centered",
  "padding-left-16",
  "padding-right-16"
)
