import React from "react"

export const Position = ({
  className = "",
  color = "#000000",
  width = "50",
}) => (
  <svg
    className={className}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 454 380"
  >
    <path
      className="cls-1"
      fillRule="evenodd"
      fill={color}
      d="M1,288V98S1.189,0.631,96,1,390,1,390,1s93.251,0.754,54,91-84,192-84,192-30.761,93.732-141,94-122,0-122,0S7.438,380.979,1,288Z"
    />
  </svg>
)
