import React from "react"
import { FormattedMessage } from "react-intl"
import mainCss from "./index.styles"

const FloatingNotification = ({ title, subtitle }) => (
  <div className={mainCss}>
    <p className="has-text-weight-bold margin-bottom-8">
      <FormattedMessage id={title} />
    </p>

    {subtitle && (
      <p>
        <FormattedMessage id={subtitle} />
      </p>
    )}
  </div>
)

export default FloatingNotification
