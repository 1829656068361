import { css, cx } from "emotion"
import vars from "~utils/variables"

export default cx(
  css`
    border-radius: 40px;
    border: 2px solid ${vars.green};
    position: absolute;
    padding-left: 21px;
    padding-right: 6px;
    line-height: 19px;
    height: 22px;
    z-index: 1;
    color: #15cc82;
    font-size: 12px;
    background-color: ${vars.white};
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.07);
    font-weight: 800;
  `
)

export const iconCss = cx(css`
  position: absolute;
  width: 30px;
  height: 30px;
  left: -12px;
  top: -5px;
`)
