import React from "react"
import { FormattedMessage } from "react-intl"
import { cx, css } from "emotion"
import mainCss, {
  profilePicCss,
  whatsappButtonCss,
  facebookButtonCss,
  messengerButtonCss,
} from "./index.styles"
import utils from "~utils"
import Button from "~components/_layout/button"

const Invite = ({ userInfo, domain }) => {
  return (
    <div className={mainCss}>
      <div className="box has-text-success is-uppercase">
        <h2>0</h2>
        <p>
          <FormattedMessage id="invite.confirmed" />
        </p>
      </div>

      <div className="box">
        <img className={profilePicCss} src={userInfo.profile_pic} alt="" />

        <p className="margin-bottom-32">{userInfo.name}</p>

        <a
          className={cx(
            whatsappButtonCss,
            css`
              height: 60px;
            `
          )}
          href={`whatsapp://send?text=${encodeURIComponent(
            `${domain}/i/${userInfo.hashid}`
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp margin-right-8" /> WhatsApp
        </a>

        <div className="columns margin-top-8">
          <div className="column">
            <a
              className={cx(
                facebookButtonCss,
                css`
                  height: 60px;
                `
              )}
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                `${domain}/i/${userInfo.hashid}`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-square margin-right-8" /> Facebook
            </a>
          </div>
          <div className="column">
            <a
              className={cx(
                messengerButtonCss,
                css`
                  height: 60px;
                `
              )}
              href={`fb-messenger://share/?link=${encodeURIComponent(
                `${domain}/i/${userInfo.hashid}`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-messenger margin-right-8" />{" "}
              Messenger
            </a>
          </div>
        </div>

        <p className="margin-bottom-16">
          <FormattedMessage id="invite.or" />
        </p>

        <div className="field has-addons">
          <div className="control is-expanded">
            <input
              className="input"
              value={`${domain}/i/${userInfo.hashid}`}
              onChange={() => {}}
              readOnly={true}
            />
          </div>
          <div className="control">
            <Button
              className="is-info"
              onClick={() =>
                utils.copyToClipboard().copy(`${domain}/i/${userInfo.hashid}`)
              }
            >
              invite.copy
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Invite
