import React from "react"
import Tag from "~components/_layout/tag"
import mainCss, { columnsCss } from "./index.styles"

const WinnersPosition = ({ val, prize }) => (
  <div className={columnsCss}>
    <div className="column is-narrow no-padding-right-mobile">
      <figure className="image is-48x48">
        <img className="is-rounded" src={val.profile_pic_url} alt="" />
      </figure>
    </div>
    <div className="column">
      <span className="has-text-weight-bold">{val.first_name}</span>
    </div>
    <div className="column is-narrow has-text-right">
      <Tag className="is-rounded">{prize}</Tag>
    </div>
  </div>
)

const Winners = ({ data, prize }) => {
  if (!data || !data.length) {
    return null
  }

  return (
    <div className={mainCss} id="winners">
      <div className="box padding-bottom-32 padding-top-32 margin-top-16">
        {data.map((val, i) => (
          <WinnersPosition key={i} val={val} prize={prize} />
        ))}
      </div>
    </div>
  )
}

export default Winners
