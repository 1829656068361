import React from "react"
import mainCss from "./index.styles"

const CountdownCircle = ({ seconds = 7, answerTime = 10 }) => {
  let circle
  let radius
  let circumference

  const [strokeDashoffset, setStrokeDashoffset] = React.useState(0)
  const [strokeDasharray, setStrokeDasharray] = React.useState(0)

  React.useEffect(() => {
    circle = document.querySelector(`#countdown-circle > circle`)
    radius = circle.r.baseVal.value
    circumference = radius * 2 * Math.PI

    setStrokeDasharray(`${circumference} ${circumference}`)

    const percent = (100 * seconds) / answerTime
    setPercent(percent < 0 ? 0 : percent)
  }, [seconds])

  React.useEffect(() => {
    const percent = (100 * (seconds - 1)) / answerTime
    setPercent(percent < 0 ? 0 : percent)
  }, [seconds])

  const setPercent = percent => {
    if (!circle) {
      return
    }

    const offset = circumference - (percent / 100) * circumference
    setStrokeDashoffset(offset)
  }

  return (
    <svg
      id="countdown-circle"
      className={`${mainCss} ${!seconds ? "inactive" : ""}`}
      width="40"
      height="40"
    >
      <circle
        className="white-circle"
        stroke="#ffffff"
        strokeDashoffset={strokeDashoffset}
        strokeDasharray={strokeDasharray}
        strokeWidth="3"
        fill="transparent"
        r="17"
        cx="20"
        cy="20"
      />
      <circle
        className="grey-circle"
        stroke="rgba(255, 255, 255, 0.3)"
        strokeDashoffset={strokeDashoffset}
        strokeDasharray={strokeDasharray}
        strokeWidth="3"
        fill="transparent"
        r="17"
        cx="20"
        cy="20"
      />
      <text x="20" y="16" dy="10px" textAnchor="middle">
        {seconds}
      </text>
    </svg>
  )
}

export default CountdownCircle
