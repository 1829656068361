import { cx, css } from "emotion"
import vars from "~utils/variables"

export const profilePicCss = cx(css`
  width: 80px;
  height: 80px;
  border: 5px solid ${vars.white};
  border-radius: ${vars.radiusRounded};
`)

export const whatsappButtonCss = cx(
  css`
    background-color: ${vars.whatsapp};
    border-color: ${vars.whatsapp};
    color: ${vars.white};

    &:hover {
      color: ${vars.whiteBis};
    }
  `,
  "button",
  "is-size-5",
  "is-medium",
  "is-fullwidth"
)

export const facebookButtonCss = cx(
  css`
    background-color: ${vars.facebook};
    border-color: ${vars.facebook};
    color: ${vars.white};

    &:hover {
      color: ${vars.whiteBis};
    }
  `,
  "button",
  "is-size-5",
  "is-medium",
  "is-fullwidth"
)

export const messengerButtonCss = cx(
  css`
    background-color: ${vars.messenger};
    border-color: ${vars.messenger};
    color: ${vars.white};

    &:hover {
      color: ${vars.whiteBis};
    }
  `,
  "button",
  "is-size-5",
  "is-medium",
  "is-fullwidth"
)

export default cx("has-text-centered", "margin-left-8", "margin-right-8")
