import { css, cx } from "emotion"

export const onlineUsersCss = cx(
  css`
    position: absolute;
    right: 20px;
    top: 20px;
  `,
  "is-white-transparent",
  "has-text-weight-bold"
)

export default cx(
  css`
    min-height: 100vh;
  `,
  "has-text-white",
  "has-text-centered",
  "section"
)
