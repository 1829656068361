import { css, cx } from "emotion"
import vars from "~utils/variables"

export const logoCss = ({ width }) =>
  cx(
    css`
      img {
        width: ${width || 100}px;
      }
    `,
    "margin-bottom-32"
  )

export const countdownCss = cx(
  css`
    > span {
      position: relative;

      > span {
        right: 0;
        left: 0;
        bottom: -3px;
        position: absolute;
      }
    }
  `,
  "is-size-1",
  "margin-top-24",
  "has-text-weight-bold"
)

export const prizeCss = ({ prizeLogo, prizeBoxBackgroundColor }) =>
  cx(
    css`
      display: inline-flex;
      position: relative;
      border-radius: ${vars.radiusLarge};
      margin-left: auto;
      margin-right: auto;
      background-color: ${prizeBoxBackgroundColor};

      &:before {
        content: "";
        background: ${vars.white};
        z-index: 2;
        border-radius: ${vars.radiusLarge} 0 0 ${vars.radiusLarge};
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 60px;
        background-image: url(${prizeLogo});
        background-position: center;
        background-size: 60%;
        background-repeat: no-repeat;
      }
    `,
    "margin-top-16",
    "margin-bottom-16",
    "padding-top-16",
    "padding-bottom-16",
    "padding-right-32",
    "padding-left-96",
    "is-size-4",
    "has-text-weight-bold"
  )

export const brandingCss = cx(
  css`
    > p {
      margin-bottom: 0.5rem;
      font-size: ${vars.size7};
    }

    > img {
      max-width: 7rem;
    }
  `,
  "margin-top-32"
)

export const profilePicCss = cx(css`
  width: 80px;
  height: 80px;
  margin-top: -20px;
  border: 5px solid ${vars.white};
  border-radius: ${vars.radiusRounded};
`)

export const userNameCss = cx("has-text-weight-bold")

export const userBoxColumnsCss = cx(
  css`
    border-top: 1px solid ${vars.greyLighter};

    > div:first-child {
      border-right: 1px solid ${vars.greyLighter};
    }
  `,
  "columns",
  "margin-top-32",
  "no-margin-left",
  "no-margin-right"
)
